import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import BigImage from "../../../assets/images/bentection_app_image.png";

const DemoSection = () => {
  return (
    <Grid container padding={4}>
      <Grid item xs={12}>
        <Typography textAlign={"center"} variant="h3">
          An Executive Benefits Sales Enablement Platform
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign={"center"} variant="h5" marginTop={2}>
          Simplify the process of offering executive benefits to your employer
          clients and their employees
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        marginTop={4}
      >
        <Box>
          <img src={BigImage} alt="main" style={{ width: "100%" }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DemoSection;
