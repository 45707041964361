import React, { useState, useCallback } from "react";
import axios from "axios";
import {
  Box,
  FormControl,
  InputLabel,
  Typography,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  Button,
} from "@mui/material";
import ReusableButton from "../../common_comp/ReusableButton";
import { colors } from "../../utils/colors";
import { Link, useNavigate } from "react-router-dom";
import BentectionLogo from "../../assets/custom_icons/slain_tech_logo";
import { primaryBlack } from "../../const/colors";
import Icon from "../../assets/images/Bentection-Logo-footer.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const types = ["Broker", "Carrier", "GA", "BGA", "Financial Advisor"];

let url =
  "https://docs.google.com/forms/u/0/d/e/1FAIpQLSdjq-oSS-4zCy5nJ6_TypIqKHWTBIoNI25zmkU_q--JXjLF8g/formResponse";

const RequestDemo = () => {
  const [inputFields, setInputFields] = useState({
    "entry.688588949": "", // first name
    "entry.1346998342": "", // last name
    "entry.843072948": "", // email
    "entry.519078016": "", // company
    "entry.1275029514": "", // phone
    "entry.534740342": "", // company type
    "entry.1994648889": "", // how can we help
  });
  const [companyType, setCompanyType] = useState("");

  const navigate = useNavigate();
  const [popUp, setPopUp] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = popUp;

  const handleClick = (newState) => () => {
    setPopUp({ open: true, ...newState });
  };

  const handleClose = () => {
    setPopUp({ ...popUp, open: false });
  };

  const handleTextInputChange = useCallback(
    (input) => (e) => {
      let { value } = e.target;

      setInputFields((previousState) => ({
        ...previousState,
        [input]: value,
      }));
    },
    []
  );

  const handleMultipleChange = useCallback(
    (input) => (e) => {
      const { value } = e.target;

      const chosenValue = types[value];

      setCompanyType(value);
      setInputFields((previousState) => ({
        ...previousState,
        [input]: chosenValue,
      }));
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setInputFields({
      "entry.688588949": "", // first name
      "entry.1346998342": "", // last name
      "entry.843072948": "", // email
      "entry.519078016": "", // company
      "entry.1275029514": "", // phone
      "entry.534740342": "", // company type
      "entry.1994648889": "", // how can we help
    });

    handleClick();
    let encodedData = new URLSearchParams(
      Object.entries(inputFields)
    ).toString();
    axios
      .post(url, encodedData, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        console.log(response);
      });

    setPopUp({ open: true, vertical: "top", horizontal: "center" });

    setTimeout(function () {
      setPopUp({ ...popUp, open: false });
    }, 3000);
  }, [inputFields, popUp]);

  return (
    <div>
      <Box
        className="p-l main-box-shadow font-l"
        sx={{ height: "15%", padding: "20px", backgroundColor: primaryBlack }}
      >
        {" "}
        <Link to={"/"}>
          {" "}
          <BentectionLogo />
        </Link>
      </Box>
      <Box className="d-flex w-80 m-auto m-t-80 demo-form-container">
        <Button
          variant="outlined"
          sx={{
            borderRadius: 10,
            padding: "8px 20px",
            textTransform: "none",
            color: primaryBlack,
            borderColor: primaryBlack,
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <ArrowBackIcon fontSize="small" style={{ marginRight: 2 }} />
          Back to Bentection home
        </Button>
      </Box>
      <div className="d-flex w-80 m-auto m-t-80 demo-form-container">
        <div style={{ width: "60%" }}>
          <Typography
            variant="h2"
            className="font-title m-t-xl font-barlow demo-title"
            style={{ marginBottom: "20px", paddingRight: "10%" }}
          >
            We would love to share a demo with you
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "70%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "30px",
            }}
          >
            Schedule your 30-minute demo to see how Bentection’s executive
            benefits sales enablement platform allows you to best serve your
            employer clients and their employees.
          </Typography>
          <Typography
            variant="p"
            className="font-barlow font-xxl demo-body-text"
            style={{
              width: "60%",
              display: "block",
              lineHeight: "32px",
              marginBottom: "70px",
            }}
          >
            Cheers!
            <br /> The Bentection Team
          </Typography>
          <div className="desktop-responsive">
            <img src={Icon} alt="bentection" />
          </div>
        </div>

        <div
          className="demo-form"
          style={{ width: "40%", marginBottom: "100px" }}
        >
          <div className="main-box-shadow p-l" style={{ borderRadius: "20px" }}>
            <Typography
              style={{
                fontSize: "22px",
                marginBottom: "22px",
                fontStyle: "normal",
                fontWeight: "400 !important",
              }}
            >
              Request Bentection demo
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                style={{ width: "48%", marginBottom: "20px", marginRight: 18 }}
                id="outlined-required"
                label="First name"
                value={inputFields["entry.688588949"]}
                name="entry.688588949"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.688588949")}
              />
              <TextField
                style={{ width: "48%", marginBottom: "20px" }}
                id="outlined-required"
                label="Last name"
                value={inputFields["entry.1346998342"]}
                name="entry.1346998342"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1346998342")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Email"
                value={inputFields["entry.843072948"]}
                name="entry.843072948"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.843072948")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Phone (optional)"
                value={inputFields["entry.1275029514"]}
                name="entry.1275029514"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1275029514")}
              />
              <TextField
                style={{ width: "100%", marginBottom: "20px" }}
                id="outlined-required"
                label="Company"
                value={inputFields["entry.519078016"]}
                name="entry.519078016"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.519078016")}
              />

              <FormControl style={{ width: "55%" }}>
                <InputLabel id="peo">Company Type</InputLabel>
                <Select
                  style={{ marginBottom: "30px" }}
                  labelId="peo"
                  id="peo"
                  value={companyType}
                  label="Company Type"
                  placeholder="Select"
                  name="entry.534740342"
                  onChange={handleMultipleChange("entry.534740342")}
                >
                  {types.map((type, i) => (
                    <MenuItem value={i}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="entry.1994648889"
                label="How can we help?"
                onFocus={(e) => (e.target.value = "")}
                onChange={handleTextInputChange("entry.1994648889")}
                rows={2}
                maxRows={4}
                style={{ width: "100%" }}
                value={inputFields["entry.1994648889"]}
                id="text-field"
                type="text"
              />

              <ReusableButton
                type="submit"
                text="Send demo request"
                backgroundColor={colors.primaryGreen}
                textColor={colors.primaryBlack}
                onClick={handleSubmit}
              />
            </form>
          </div>
        </div>

        <div className="mobile-responsive demo-logo">
          <img src={Icon} alt="bentection" />
        </div>
      </div>
      <div
        className="m-auto font-barlow text-center"
        style={{
          borderTop: "1.5px solid black",
          paddingTop: "25px",
          paddingBottom: "25px",
          marginTop: "120px",
          width: "95%",
        }}
      >
        {" "}
        <p style={{ fontSize: "12px", fontWeight: "500" }}>
          Copyright © Bentection Inc. 2023. All Rights Reserved.&nbsp; |&nbsp;{" "}
          <a href="google.com" className="primary-black-text">
            Privacy Policy
          </a>{" "}
          &nbsp;|&nbsp;{" "}
          <a className="primary-black-text" href="mailto:hello@bentection.com">
            Contact Us
          </a>
        </p>
      </div>
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="Message sent! Please allow 24-48 hours for a response"
          key={vertical + horizontal}
        />
      </div>
    </div>
  );
};

export default RequestDemo;
