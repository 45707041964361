import React from "react";
import { Grid, Typography } from "@mui/material";

import "@fontsource/roboto/400.css";
import { colors } from "../../../utils/colors";
import {
  IconOne,
  IconTwo,
  IconThree,
} from "../../../assets/custom_icons/fold_icons";

const RectangleCards = () => {
  let cards = [
    {
      title: "Block of business level visualization",
      icon: <IconOne />,
      body: "Easily see how you can best serve your clients, and which you should focus on that have the biggest gap in coverage",
    },
    {
      title: "Employer level visualization",
      icon: <IconTwo />,
      body: "Sit with a business owner or decision maker and quickly analyze the value executive benefits can offer.",
    },
    {
      title: "Executive level visualization",
      icon: <IconThree />,
      body: "Tell the income gap story with real-time visualization and provide dynamic reports to help drive education & awareness",
    },
    // {
    //   title: "B.O.B. Management",
    //   icon: <Icon_four />,
    //   body: "Protect your book of business against the open market with real-time competitive insights",
    // },
  ];
  return (
    <Grid
      container
      className="card-gradient overflow-hidden p-b-l"
      id="rectangle-cards"
      style={{ height: "440px" }}
    >
      <Grid
        item
        className="main-box-shadow m-b-xl main-border-radius"
        sx={{ width: "80%", height: "100%", margin: "auto" }}
      >
        <Grid
          container
          className="primary-white-text d-flex primary-white-bc main-border-radius"
        >
          {cards.map((card, i) => (
            <Grid
              item
              sx={12}
              md={4}
              lg={4}
              key={i}
              className="rectangle-cards"
            >
              <div
                style={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "none",
                }}
                className="main-border-radius"
              >
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="m-auto"
                >
                  <div
                    className="p-b-xl font-barlow p-t-xl"
                    style={{
                      fontSize: "32px",
                      fontWeight: "600",
                      color: colors.primaryBlack,
                      width: 350,
                    }}
                  >
                    {card.title}
                  </div>
                  <div className="p-b-m">{card.icon}</div>

                  <Typography
                    sx={{
                      fontSize: 14,
                      width: "50%",
                      margin: "auto",
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {card.body}
                  </Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RectangleCards;
