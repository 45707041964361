import "./App.css";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/homepage";
import ContactUs from "./pages/contactus";
import RequestDemo from "./pages/demo/RequestDemo";
// import Layout from "./pages/layout";
import "./css/responsive/scroll_section/style.css";

function App() {
  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/request-demo" element={<RequestDemo />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* </Route> */}
      </Routes>
    </div>
  );
}

export default App;
