import React from "react";

const BlackSlainTechLogo = () => {
  return (
    <svg
      width="158"
      height="30"
      viewBox="0 0 158 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.362 29.3039C8.38601 29.3039 6.66334 28.9873 5.19401 28.3539C3.72468 27.7206 2.58468 26.8339 1.77401 25.6939C0.988675 24.5539 0.596008 23.2113 0.596008 21.6659V20.7159C0.596008 20.5893 0.634008 20.4879 0.710008 20.4119C0.811342 20.3106 0.925342 20.2599 1.05201 20.2599H4.43401C4.56067 20.2599 4.66201 20.3106 4.73801 20.4119C4.83934 20.4879 4.89001 20.5893 4.89001 20.7159V21.4379C4.89001 22.6033 5.40934 23.5786 6.44801 24.3639C7.48668 25.1239 8.90534 25.5039 10.704 25.5039C12.2493 25.5039 13.4147 25.1746 14.2 24.5159C14.9853 23.8573 15.378 23.0086 15.378 21.9699C15.378 21.2606 15.1627 20.6653 14.732 20.1839C14.3013 19.6773 13.6807 19.2213 12.87 18.8159C12.0847 18.4106 10.894 17.9039 9.29801 17.2959C7.49934 16.6373 6.04268 16.0166 4.92801 15.4339C3.81334 14.8513 2.87601 14.0659 2.11601 13.0779C1.38134 12.0646 1.01401 10.8106 1.01401 9.31595C1.01401 7.08661 1.82467 5.32595 3.44601 4.03395C5.06734 2.74195 7.23334 2.09595 9.94401 2.09595C11.844 2.09595 13.516 2.42528 14.96 3.08395C16.4293 3.74261 17.5693 4.66728 18.38 5.85795C19.1907 7.02328 19.596 8.37861 19.596 9.92395V10.5699C19.596 10.6966 19.5453 10.8106 19.444 10.9119C19.368 10.9879 19.2667 11.0259 19.14 11.0259H15.72C15.5933 11.0259 15.4793 10.9879 15.378 10.9119C15.302 10.8106 15.264 10.6966 15.264 10.5699V10.1519C15.264 8.96128 14.77 7.96061 13.782 7.14995C12.8193 6.31395 11.4767 5.89595 9.75401 5.89595C8.36068 5.89595 7.27134 6.18728 6.48601 6.76995C5.72601 7.35261 5.34601 8.17595 5.34601 9.23995C5.34601 9.99995 5.54868 10.6206 5.95401 11.1019C6.35934 11.5833 6.96734 12.0266 7.77801 12.4319C8.58867 12.8119 9.84268 13.3059 11.54 13.9139C13.3387 14.5979 14.77 15.2186 15.834 15.7759C16.9233 16.3333 17.848 17.1186 18.608 18.1319C19.3933 19.1199 19.786 20.3613 19.786 21.8559C19.786 24.1359 18.9373 25.9473 17.24 27.2899C15.568 28.6326 13.2753 29.3039 10.362 29.3039Z"
        fill="black"
      />
      <path
        d="M24.2632 28.9999C24.1365 28.9999 24.0225 28.962 23.9212 28.886C23.8452 28.7846 23.8072 28.6706 23.8072 28.5439V2.85595C23.8072 2.72928 23.8452 2.62795 23.9212 2.55195C24.0225 2.45061 24.1365 2.39995 24.2632 2.39995H27.7592C27.8858 2.39995 27.9872 2.45061 28.0632 2.55195C28.1645 2.62795 28.2152 2.72928 28.2152 2.85595V28.5439C28.2152 28.6706 28.1645 28.7846 28.0632 28.886C27.9872 28.962 27.8858 28.9999 27.7592 28.9999H24.2632Z"
        fill="black"
      />
      <path
        d="M40.309 9.27795C41.905 9.27795 43.311 9.55661 44.527 10.1139C45.743 10.6713 46.6803 11.4439 47.339 12.4319C47.9976 13.3946 48.327 14.4966 48.327 15.7379V28.5439C48.327 28.6706 48.2763 28.7846 48.175 28.886C48.099 28.962 47.9976 28.9999 47.871 28.9999H44.375C44.2483 28.9999 44.1343 28.962 44.033 28.886C43.957 28.7846 43.919 28.6706 43.919 28.5439V27.3279C43.919 27.2519 43.8936 27.2139 43.843 27.2139C43.7923 27.1886 43.7416 27.2013 43.691 27.2519C42.4496 28.6199 40.651 29.3039 38.295 29.3039C36.395 29.3039 34.8243 28.8226 33.583 27.8599C32.367 26.8973 31.759 25.4659 31.759 23.5659C31.759 21.5899 32.443 20.0573 33.811 18.9679C35.2043 17.8533 37.155 17.2959 39.663 17.2959H43.729C43.8556 17.2959 43.919 17.2326 43.919 17.1059V16.1939C43.919 15.2059 43.6276 14.4459 43.045 13.9139C42.4623 13.3566 41.5883 13.0779 40.423 13.0779C39.4603 13.0779 38.675 13.2553 38.067 13.6099C37.4843 13.9393 37.117 14.4079 36.965 15.0159C36.889 15.3199 36.7116 15.4466 36.433 15.3959L32.747 14.9399C32.6203 14.9146 32.5063 14.8766 32.405 14.8259C32.329 14.7499 32.3036 14.6613 32.329 14.5599C32.5316 13.0146 33.355 11.7479 34.799 10.7599C36.2683 9.77194 38.105 9.27795 40.309 9.27795ZM39.397 25.8079C40.6383 25.8079 41.7023 25.4786 42.589 24.8199C43.4756 24.1613 43.919 23.3253 43.919 22.3119V20.5259C43.919 20.3993 43.8556 20.3359 43.729 20.3359H40.537C39.1943 20.3359 38.1303 20.5893 37.345 21.0959C36.585 21.6026 36.205 22.3246 36.205 23.2619C36.205 24.0726 36.4963 24.7059 37.079 25.1619C37.687 25.5926 38.4596 25.8079 39.397 25.8079Z"
        fill="black"
      />
      <path
        d="M53.4365 28.9999C53.3098 28.9999 53.1958 28.962 53.0945 28.886C53.0185 28.7846 52.9805 28.6706 52.9805 28.5439V9.99995C52.9805 9.87328 53.0185 9.77195 53.0945 9.69595C53.1958 9.59461 53.3098 9.54395 53.4365 9.54395H56.9325C57.0592 9.54395 57.1605 9.59461 57.2365 9.69595C57.3378 9.77195 57.3885 9.87328 57.3885 9.99995V28.5439C57.3885 28.6706 57.3378 28.7846 57.2365 28.886C57.1605 28.962 57.0592 28.9999 56.9325 28.9999H53.4365Z"
        fill="black"
      />
      <path
        d="M71.748 9.27795C73.8 9.27795 75.434 9.88595 76.65 11.1019C77.8914 12.3179 78.512 13.9773 78.512 16.0799V28.5439C78.512 28.6706 78.4614 28.7846 78.36 28.886C78.284 28.962 78.1827 28.9999 78.056 28.9999H74.56C74.4334 28.9999 74.3194 28.962 74.218 28.886C74.142 28.7846 74.104 28.6706 74.104 28.5439V16.9919C74.104 15.8266 73.7747 14.8893 73.116 14.1799C72.4574 13.4453 71.5834 13.0779 70.494 13.0779C69.4047 13.0779 68.518 13.4326 67.834 14.1419C67.1754 14.8513 66.846 15.7886 66.846 16.9539V28.5439C66.846 28.6706 66.7954 28.7846 66.694 28.886C66.618 28.962 66.5167 28.9999 66.39 28.9999H62.894C62.7674 28.9999 62.6534 28.962 62.552 28.886C62.476 28.7846 62.438 28.6706 62.438 28.5439V10.0379C62.438 9.91128 62.476 9.80995 62.552 9.73395C62.6534 9.63261 62.7674 9.58195 62.894 9.58195H66.39C66.5167 9.58195 66.618 9.63261 66.694 9.73395C66.7954 9.80995 66.846 9.91128 66.846 10.0379V11.2539C66.846 11.3299 66.8714 11.3806 66.922 11.4059C66.9727 11.4313 67.0107 11.4059 67.036 11.3299C68.0747 9.96195 69.6454 9.27795 71.748 9.27795Z"
        fill="black"
      />
      <path
        d="M99.106 2.39995C99.2326 2.39995 99.334 2.45061 99.41 2.55195C99.5113 2.62795 99.562 2.72928 99.562 2.85595V5.78195C99.562 5.90861 99.5113 6.02261 99.41 6.12395C99.334 6.19995 99.2326 6.23795 99.106 6.23795H92.114C91.9873 6.23795 91.924 6.30128 91.924 6.42795V28.5439C91.924 28.6706 91.8733 28.7846 91.772 28.886C91.696 28.962 91.5946 28.9999 91.468 28.9999H87.972C87.8453 28.9999 87.7313 28.962 87.63 28.886C87.554 28.7846 87.516 28.6706 87.516 28.5439V6.42795C87.516 6.30128 87.4526 6.23795 87.326 6.23795H80.6C80.4733 6.23795 80.3593 6.19995 80.258 6.12395C80.182 6.02261 80.144 5.90861 80.144 5.78195V2.85595C80.144 2.72928 80.182 2.62795 80.258 2.55195C80.3593 2.45061 80.4733 2.39995 80.6 2.39995H99.106Z"
        fill="black"
      />
      <path
        d="M116.586 17.1059C116.687 17.7393 116.738 18.4486 116.738 19.2339L116.7 20.4119C116.7 20.7159 116.548 20.8679 116.244 20.8679H104.35C104.223 20.8679 104.16 20.9313 104.16 21.0579C104.236 21.8939 104.35 22.4639 104.502 22.768C105.11 24.5919 106.592 25.5166 108.948 25.5419C110.671 25.5419 112.039 24.8326 113.052 23.4139C113.153 23.2619 113.28 23.1859 113.432 23.1859C113.533 23.1859 113.622 23.2239 113.698 23.2999L116.054 25.2379C116.282 25.4153 116.32 25.6179 116.168 25.8459C115.383 26.9606 114.331 27.8219 113.014 28.4299C111.722 29.0379 110.278 29.3419 108.682 29.3419C106.706 29.3419 105.021 28.8986 103.628 28.0119C102.26 27.1253 101.259 25.8839 100.626 24.2879C100.043 22.9199 99.752 21.0199 99.752 18.5879C99.752 17.1186 99.904 15.9279 100.208 15.0159C100.715 13.2426 101.665 11.8493 103.058 10.8359C104.477 9.79728 106.174 9.27795 108.15 9.27795C113.141 9.27795 115.953 11.8873 116.586 17.1059ZM108.15 13.0779C107.213 13.0779 106.427 13.3186 105.794 13.7999C105.186 14.2559 104.755 14.9019 104.502 15.7379C104.35 16.1686 104.249 16.7259 104.198 17.4099C104.147 17.5366 104.198 17.5999 104.35 17.5999H112.064C112.191 17.5999 112.254 17.5366 112.254 17.4099C112.203 16.7766 112.127 16.2953 112.026 15.9659C111.798 15.0539 111.342 14.3446 110.658 13.8379C109.999 13.3313 109.163 13.0779 108.15 13.0779Z"
        fill="black"
      />
      <path
        d="M128.861 29.3039C126.91 29.3039 125.225 28.8099 123.807 27.8219C122.388 26.8086 121.4 25.4533 120.843 23.7559C120.437 22.6159 120.235 21.0959 120.235 19.1959C120.235 17.3719 120.437 15.8773 120.843 14.7119C121.375 13.0399 122.35 11.7226 123.769 10.7599C125.213 9.77194 126.91 9.27795 128.861 9.27795C130.837 9.27795 132.559 9.77194 134.029 10.7599C135.498 11.7479 136.473 12.9893 136.955 14.4839C137.081 14.8893 137.17 15.2946 137.221 15.6999V15.7759C137.221 16.0293 137.081 16.1813 136.803 16.2319L133.383 16.7259H133.307C133.079 16.7259 132.927 16.5993 132.851 16.3459C132.851 16.2193 132.838 16.1179 132.813 16.0419C132.762 15.8393 132.711 15.6619 132.661 15.5099C132.433 14.8006 131.977 14.2179 131.293 13.7619C130.609 13.3059 129.798 13.0779 128.861 13.0779C127.898 13.0779 127.075 13.3313 126.391 13.8379C125.732 14.3193 125.289 14.9906 125.061 15.8519C124.807 16.6626 124.681 17.7899 124.681 19.2339C124.681 20.6273 124.795 21.7546 125.023 22.6159C125.276 23.5026 125.732 24.2119 126.391 24.7439C127.049 25.2506 127.873 25.5039 128.861 25.5039C129.823 25.5039 130.647 25.2633 131.331 24.7819C132.04 24.2753 132.496 23.604 132.699 22.768C132.724 22.7173 132.737 22.6539 132.737 22.5779C132.762 22.5526 132.775 22.5146 132.775 22.4639C132.851 22.1599 133.028 22.0333 133.307 22.0839L136.727 22.6159C136.853 22.6413 136.955 22.7046 137.031 22.8059C137.132 22.8819 137.17 22.9959 137.145 23.1479C137.069 23.5533 137.005 23.8446 136.955 24.0219C136.473 25.6433 135.498 26.9353 134.029 27.8979C132.559 28.8353 130.837 29.3039 128.861 29.3039Z"
        fill="black"
      />
      <path
        d="M150.264 9.27795C152.316 9.27795 153.95 9.88595 155.166 11.1019C156.407 12.3179 157.028 13.9773 157.028 16.0799V28.5439C157.028 28.6706 156.977 28.7846 156.876 28.886C156.8 28.962 156.699 28.9999 156.572 28.9999H153.076C152.949 28.9999 152.835 28.962 152.734 28.886C152.658 28.7846 152.62 28.6706 152.62 28.5439V16.9919C152.62 15.8266 152.291 14.8893 151.632 14.1799C150.973 13.4453 150.099 13.0779 149.01 13.0779C147.921 13.0779 147.034 13.4326 146.35 14.1419C145.691 14.8513 145.362 15.7886 145.362 16.9539V28.5439C145.362 28.6706 145.311 28.7846 145.21 28.886C145.134 28.962 145.033 28.9999 144.906 28.9999H141.41C141.283 28.9999 141.169 28.962 141.068 28.886C140.992 28.7846 140.954 28.6706 140.954 28.5439V2.85595C140.954 2.72928 140.992 2.62795 141.068 2.55195C141.169 2.45061 141.283 2.39995 141.41 2.39995H144.906C145.033 2.39995 145.134 2.45061 145.21 2.55195C145.311 2.62795 145.362 2.72928 145.362 2.85595V11.2539C145.362 11.3299 145.387 11.3806 145.438 11.4059C145.489 11.4313 145.527 11.4059 145.552 11.3299C146.591 9.96195 148.161 9.27795 150.264 9.27795Z"
        fill="black"
      />
      <circle cx="55" cy="4" r="3" fill="#5AE0A0" />
      <circle cx="41" cy="4" r="3" fill="#5AE0A0" />
    </svg>
  );
};

export default BlackSlainTechLogo;
