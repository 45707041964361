import React from "react";
import { Box } from "@mui/material";

import ReusableButton from "../../../common_comp/ReusableButton";
import { colors } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import Icon from "../../../assets/images/Bentection-Logo-footer.svg";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      width={"100%"}
      paddingTop={"120px"}
      className="primary-green-bc text-center"
    >
      <img src={Icon} alt="bentection" />
      <h2 className="text-bolder font-title m-t-xl font-barlow primary-black-text p-b-m">
        Be ready for every curve <br /> in the road
      </h2>
      <ReusableButton
        text="Get started"
        backgroundColor={colors.primaryBlack}
        textColor={colors.primaryGreen}
        padding="7px 60px"
        onClick={() => {
          navigate("/request-demo");
        }}
      />
      <Box
        className="m-auto font-barlow"
        borderTop={"1.5px solid black"}
        padding={"25px 0"}
        marginTop={"120px"}
        width={"95%"}
      >
        {" "}
        <p style={{ fontSize: "12px", fontWeight: "500" }}>
          Copyright © Bentection Inc. 2024. All Rights Reserved.&nbsp; |&nbsp;{" "}
          <a href="google.com" className="primary-black-text">
            Privacy Policy
          </a>{" "}
          &nbsp;|&nbsp;{" "}
          <a className="primary-black-text" href="mailto:hello@bentection.com">
            Contact Us
          </a>
        </p>
      </Box>
    </Box>
  );
};

export default Footer;
