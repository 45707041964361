import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import ReusableButton from "../../../common_comp/ReusableButton";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { colors } from "../../../utils/colors";
import "@fontsource/roboto/400.css";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { primaryGreen, primaryWhite } from "../../../const/colors";
import BentectionLogo from "../../../assets/custom_icons/slain_tech_logo.js";
import BigImage from "../../../assets/images/bentection-product.png";

const demoButtonStyle = {
  fontSize: "18px",
  color: "#381E72",
};

const Hero = () => {
  const navigate = useNavigate();
  return (
    <Grid className="h-vh hero-gradient overflow-hidden">
      <Box
        className="primary-white-text p-l"
        width={"100%"}
        height={"15%"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box>
          <BentectionLogo />
        </Box>
        <Box marginRight={8}>
          <Button
            variant="outlined"
            style={{
              borderColor: "white",
              borderRadius: 25,
              padding: "6px 30px",
              color: colors.primaryGreen,
            }}
            onClick={() => {
              window.location.href = "https://app.bentection.com/login";
            }}
          >
            Log in
          </Button>
        </Box>
      </Box>
      <Grid container className="primary-white-text hero-container">
        <Grid item sx={12} md={12} lg={6} margin={"auto"}>
          <Grid
            width={"80%"}
            marginLeft={"14%"}
            textAlign={"left"}
            marginBottom={4}
          >
            <Typography fontWeight={100} variant="subtitle1">
              Be <span style={{ color: primaryGreen }}>ready</span> for every{" "}
              <span style={{ color: primaryGreen }}>curve</span> in the road
            </Typography>
          </Grid>
          <Grid
            className="hero-text-section"
            width={"70%"}
            marginLeft={"14%"}
            textAlign={"left"}
          >
            <Typography
              fontWeight={600}
              variant="h3"
              className="font-title m-t-xl font-barlow"
            >
              An <span style={{ color: primaryGreen }}>Executive Benefits</span>
            </Typography>
            <Typography
              fontWeight={600}
              variant="h3"
              className="font-title m-t-xl font-barlow"
            >
              Sales Enablement Platform
            </Typography>
            <ReusableButton
              text="Request a demo"
              icon={ChatBubbleOutlineOutlinedIcon}
              iconStyles={demoButtonStyle}
              backgroundColor={colors.primaryGreen}
              textColor={colors.primaryBlack}
              onClick={() => {
                navigate("/request-demo");
              }}
            />
            <Typography
              className="font-14"
              marginTop={"32px"}
              letterSpacing={"0.5"}
            >
              Questions?{" "}
              <a href="mailto:hello@bentection.com">Contact our team</a>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className="hero-image-container"
          item
          sx={12}
          md={12}
          lg={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <div margin={"auto"} className="big-image">
            <img src={BigImage} alt="main" style={{ width: "100%" }} />
          </div>
        </Grid>
      </Grid>
      <Grid
        width={"100%"}
        height="10%"
        marginTop={"3%"}
        textAlign={"center"}
        marginBottom={"3%"}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            backgroundColor: primaryWhite,
            color: "black",
            borderRadius: 10,
            padding: "8px 20px",
          }}
        >
          <Typography textAlign="center">
            <ArrowDownwardIcon
              style={{
                fontSize: "16px",
                color: "black",
                marginBottom: "-3px",
              }}
            />{" "}
            <Link
              style={{ cursor: "pointer", color: "black", fontSize: "14px" }}
              smooth={true}
              hashSpy={true}
              duration={500}
              to="rectangle-cards"
            >
              Learn more{" "}
            </Link>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Hero;
